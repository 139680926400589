  import React from 'react'
  import Layout from '../../../../components/App/Layout'
  import Navbar from '../../../../components/App/NavbarRV'
  import Footer from '../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../assets/images/pages/2-2-1-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-assure/'>Expertise d'assuré</a></li><li><a href='/expertise-assure/expert-assure/'>L'expert d'assuré</a></li><li>Role</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Le rôle de l’Expert d’assuré : vous accompagner jusqu’à juste indemnisation de votre sinistre</h1>

<p>L’Expert d’assuré n’est pas un Expert d’assurance.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Role" />
</div>

<p>Il travaille de façon neutre, indépendante et est mandaté par vos soins.</p>
<p>l’Expert d’assuré a pour rôle principal de vous représenter et <strong>de défendre vos intérêts </strong>lors d’un litige, un contentieux envers une assurance et une entreprise de travaux.</p>
<p>Il vous accompagne aussi bien sur le plan technique (détermination des causes d’un sinistre, travaux de reprises..) que sur la médiation avec l’assurance ou l’entreprise.</p>
<p>Il endosse ainsi un rôle de conciliateur pour régler les conflits à l’amiable.</p>
<h2>L’Expert d’assuré, Un professionnel du bâtiment indépendant</h2>

<p>Les Experts du cabinet RV Expertises sont des professionnels du bâtiment indépendants et impartiaux. Ils travaillent pour le compte de particuliers, entreprises et des assurés afin d’ apporter conseils techniques et assistance dans la résolution de conflits.</p>

<p>Ils vont, lors de l’ouverture du dossier, définir les contours de votre étude et définir avec vous vos besoins et vos attentes.</p>

<p>Lorsque vous mandatez un expert d’assuré, celui-ci intervient par la suite pour une visite technique pour expertise suite à tout type de sinistres :</p>
<ul>
    <li>malfaçons,</li>
<li>sinistre catastrophe naturelle (sécheresse, inondation, incendies)</li>
</ul>

<p>Il apporte un rôle de médiation : </p>
<ul>
<li>Il correspond avec la partie adverse (Expert d’assurance, entreprise…) pour la bonne tenue de votre dossier,</li>
<li>Il vous accompagne jusqu’au Procès verbal qui clôt les opérations d’expertise,</li>
<li>il vérifie avec vous la quittance d’indemnisation et s’assure de votre juste indemnisation.</li></ul>

<p>L’Expert d’assuré, vous défend techniquement mais est également l’interlocuteur privilégié entre vous et l’Assurance ou une entreprise exécutante des travaux, lors de litige.</p>

<h2>Besoin d’un Expert d’assuré pour défendre vos intérêts ?</h2>

<p>En cas de litige avec votre assurance, une entreprise ou un tiers, le cabinet RV Expertises met en place une expertise personnalisée afin de répondre à vos besoins afin de défendre vos intérêts.</p>

<p>N’hésitez pas à contacter le cabinet RV Expertises, spécialiste de la défense des assurés en Expertise construction et catastrophes naturelles. </p>


<p> </p>
<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un Expert d’assuré</a>
    </div>
</div>


                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-assure/expert-assure/role/' className='active'>Role</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details